<template>
    <div>
        <!-- title="跟进简历" -->
        <el-dialog
            class="fllow_resume_dialog"
            :visible.sync="fllowDialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="560px"
            :before-close="handleClose">
            <!-- 简历更新成功，请完成推荐选项 -->
            <div slot="title" class="header">
                <span>跟进简历</span>
                <i class="title-tip" v-if="hasUpdateResume">简历更新成功，请完成推荐选项</i>
            </div>
            <div class="content">
                <el-form
                    ref="recommendResumeForm"
                    class="recommend-resume-form"
                    :rules="rules"
                    :model="recommendResumeForm"
                >
                    <!-- 推荐城市 -->
                    <el-form-item label="所在地区" prop="locations">
                        <el-input readOnly="true" @click.native="showCitySelect" placeholder="请选择，最多选择5项" :value="locationName"></el-input>
                        <div class="hot-city">
                            <span v-for="(city, key) in hotCityMap" :key="key" @click="selectHotCity(city.label, city.value)">{{city.label}}</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="业务分类" prop="BusinessClassificationId">
                        <el-select style="width: 100%;" v-model="recommendResumeForm.BusinessClassificationId" placeholder="请选择目标业务分类">
                            <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推荐原因" prop="RecommendReason">
                        <el-input
                            type="textarea"
                            :rows="6"
                            placeholder="填写对候选人的评价和推荐评语，候选人的职业预期，期望薪资等"
                            v-model="recommendResumeForm.RecommendReason"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="onsubmit" :loading="onSubmitLoading">推 荐</el-button>
            </span>
        </el-dialog>

        <select-city-dialog ref="selectCityDialog" :has-primary-city="true" @city-selected="handleCitySelect"></select-city-dialog>
    </div>
</template>

<script>
import hotCityMap from '#/js/config/hotCity.json';
import SelectCityDialog from '#/component/common/dialog/select-city-dialog.vue';
import { recommend as recommendUrl } from '@src/js/config/api.json';
import jobService from '#/js/service/jobService.js';
import businessClassificationService from '#/js/service/businessClassificationService.js';

export default {
    props: {
        hasUpdateResume: {
            type: Boolean,
            default: false
        },
        from: {
            type: String,
            default: '',
        },
    },
    components: {
        SelectCityDialog,
    },
    data() {
        return {
            fllowDialogVisible: false,
            onSubmitLoading: false,

            jobId: '',
            publicJobDeliveryId: '',
            candidateId: '',
            
            recommendResumeForm: {
                locations: [],
                RecommendReason: '',
                BusinessClassificationId: '',
            },
            options: [],
            locationName: '',
            hotCityMap: hotCityMap,

            rules: {
                locations: [
                    { required: true, message: '请选择所在地区', trigger: ['blur', 'change'] }
                ],
                BusinessClassificationId: [
                    { required: true, message: '请选择业务分类', trigger: ['blur', 'change'] },
                ],
                RecommendReason: [
                    { required: true, message: '请填写推荐原因', trigger: ['blur', 'change'] },
                    { min: 20, message: '推荐原因请至少填写20个字符', trigger: ['change', 'blur'] },
                ]
            },
        };
    },

    mounted() {
        
    },
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
    },

    methods: {
        show(item, candidateId) {
            this.jobId = item.jobId;
            this.publicJobDeliveryId = item.id;
            this.candidateId = candidateId;

            this.getOptionsList();
            this.fllowDialogVisible = true;
        },
        getOptionsList() {
            let params = {
                start: 0,
                take: 100
            }
            businessClassificationService
                .getBusinessClassificationList(params).then(res => {
                    // console.log(res);
                    this.options = res.list;
                }).catch(err => {
                    console.log(err);
                })
        },

        resetForm() {
            this.$refs['recommendResumeForm'].resetFields();
        },

        handleClose() {
            this.fllowDialogVisible = false;
            // 重置
            this.locationName = '';
            this.recommendResumeForm.locations = [];
            this.recommendResumeForm.RecommendReason = '';
            this.resetForm();
        },
        selectHotCity(name, code) {
            if(this.recommendResumeForm.locations.length == 5) {
                shortTips('最多选择五项地区！');
                return false;
            }
            if(this.recommendResumeForm.locations.indexOf(code) > -1) {
                return false;
            }
            this.recommendResumeForm.locations.push(code);
            switch(name) {
                case '北':
                name = '北京';
                break;
                case '上':
                name = '上海';
                break;
                case '广': 
                name = '广州';
                break;
                case '深':
                name = '深圳';
                break;
                default: 
                break;
            }

            if(this.locationName) {
                this.locationName = `${this.locationName},${name}`;
            }else {
                this.locationName = name;
            }
        },
        showCitySelect() {
            this.$refs.selectCityDialog.show();
            this.$nextTick(() => {
                if(this.$refs.selectCityDialog.$refs.provinceCity) {
                    this.$refs.selectCityDialog.$refs.provinceCity.clearSelected();
                    this.$refs.selectCityDialog.$refs.provinceCity.setCheckedCityText(this.recommendResumeForm.locations);
                }
            })
        },
        handleCitySelect() {
            let selected = this.$refs.selectCityDialog.selectedCity;
            let target = [];
            let targetNames = [];
            selected.forEach(item => {
                target.push(item.id);
                targetNames.push(item.text);
            })
            this.recommendResumeForm.locations = target;
            this.locationName = targetNames.join(',');
        },
        onsubmit() {
            this.$refs.recommendResumeForm.validate((valid) => {
                if (valid) {
                    // console.log('submit!');
                    // console.log(this.recommendResumeForm);

                    this.onSubmitLoading = true;

                    // 先抢单，再推荐（如果抢单成功但推荐失败，再次跟进调抢单会报错要忽略报错直接推荐）
                    this.grabOrder();

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 抢单
        grabOrder() {
            const params = { 
                jobId: this.jobId, 
            };
            jobService.grabHrJob(params)
                .then((res)=>{
                    console.log(res);

                    this.recommend();
                }).catch(err => {
                    this.recommend();
                });
        },
        // 推荐
        recommend() {
            let formData = new FormData();
            formData.append(`PublicJobDeliveryId`, this.publicJobDeliveryId);
            formData.append(`candidateId`, this.candidateId);
            formData.append(`candidateSourceChannel`, 'csdn大厂机会');
            formData.append('RecommendReason', this.recommendResumeForm.RecommendReason);
            formData.append('location', this.recommendResumeForm.locations[0]);
            this.recommendResumeForm.locations.forEach((location,index) => {
                // formData.append(`locations[${index}]`, location);
                formData.append('locations[]', location);
            });
            if(this.isCFUser) {
                formData.append(`BusinessClassificationId`, this.recommendResumeForm.BusinessClassificationId);
            }
            // console.log(formData);

            _request({
                url: recommendUrl.job_recommend.replace(/\%p/g, this.jobId),
                method: "POST",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: formData,
                throwBusinessError: true
            }).then(res => {
                console.log(res);
                if(!this.from) {
                    sessionStorage.setItem('recommendationId', res?.recommendationId);
                    sessionStorage.setItem('recommendShareCode', res?.code);
                    shortTips('跟进成功!');
                    this.onSubmitLoading = false;
                    this.fllowDialogVisible = false;

                    // 跟进推荐成功刷新投递管理列表
                    this.$emit('update-deliver-list');
                } else {
                    shortTips('跟进成功，请在推荐流程中继续跟进!');
                    this.onSubmitLoading = false;
                    this.fllowDialogVisible = false;
                    setTimeout(() => {
                        location.href = `/#/corporationOpportunity?activeTabName=deliveryManage`;
                    }, 2000);
                }
            }).catch(err => {
                let errorMsg = [];
                if(err.data) {
                    Object.keys(err.data).forEach(key => {
                        if(err.data[key].length > 0) {
                            errorMsg.push(err.data[key][0]);
                        }
                    });
                }
                if(err.message) {
                    errorMsg.push(err.message);
                }
                if(errorMsg.length > 0) {
                    shortTips(errorMsg.join("，"));
                }

                this.onSubmitLoading = false;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.fllow_resume_dialog {
    .header {
        font-size: 18px;
        color: #444;
        .title-tip {
            display: inline-block;
            margin-left: 20px;
            font-size: 14px;
            color: #FA765D;
        }  
    }
    .content {
        .recommend-resume-form {
            .el-form-item {
                display: flex;
                /deep/ .el-form-item__content {
                    width: 84%;
                }
            }
            .hot-city {
                @include clearfix();

                span {
                    display: inline-block;
                    padding: 0 4px;
                    color: $primary;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
</style>